import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Footer from "../components/footer"

const IndexPage = ({ data }) => {

  const pages = data.allSitePage.edges.filter(
      o => o.node.path.indexOf('404') === -1 && o.node.path !== '/'
    ).map(
      o =>
        <Link to={o.node.path} className="fl w-50 w-25-l link overflow-hidden">
          <div role="img" aria-label="aaa" className="grow aspect-ratio--4x6" style={{
            'background': 'url(./' + o.node.path + '.jpg) no-repeat center center',
            'backgroundSize': 'cover'
          }}></div>
        </Link>
    )

  return (
    <Layout>
      <Helmet>
        <title>County Data Stories by CTData Collaborative</title>
      </Helmet>

      <h1 className="f1 mw8 center">
        Connecticut County Data Stories
      </h1>

      <div className="bg-light-bluen h-100 vw-100 mb3 cf">
        <article>
          {pages}
        </article>
      </div>

      <Footer />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`